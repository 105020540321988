import {
  type QueryObserverOptions,
  queryOptions,
  useQuery,
} from "@tanstack/react-query"
import { useStore } from "zustand"

import { SECOND } from "@common/utils/date"
import type { ContextStoreProp } from "@future/context/store"
import { Collateral, NativeToken } from "@future/numerics"
import type { MarketId } from "@perps/sdk/types"
import { useChainStore } from "@future/libs/chain/store"
import type CosmosClient from "@perps/sdk/client/CosmosClient"
import type { AllMarketMap } from "@future/market/status/types"

export type WalletBalanceMap = Map<MarketId, WalletBalance>

interface WalletBalance {
  collateral: Collateral
  gas: NativeToken
}

export const walletMarketBalancesQueryOptions = (
  chainClient: CosmosClient,
  chainDenom: string,
  markets: AllMarketMap,
  walletAddress: string | undefined,
) => {
  return queryOptions({
    queryKey: ["walletBalances", chainDenom, walletAddress],
    refetchInterval: SECOND * 30,
    queryFn: async (context) => {
      if (!walletAddress) {
        return new Map()
      }

      const coins = await chainClient.queryAllNativeCoinBalances(
        walletAddress,
        context.signal,
      )

      const gasPromise = chainClient
        .queryWalletBalance({
          token: {
            native: {
              denom: chainDenom,
              decimal_places: 6,
            },
          },
          address: walletAddress,
          coins,
          abortSignal: context.signal,
        })
        .then((gasValue) => {
          return new NativeToken(gasValue)
        })

      const collateralsPromises = [...markets.values()].map(async (market) => {
        const collateralValue = await chainClient.queryWalletBalance({
          token: market.config.collateralToken,
          address: walletAddress,
          coins,
          abortSignal: context.signal,
        })
        const collateral = new Collateral(collateralValue)
        return { marketId: market.config.id, collateral }
      })
      const collateralsPromise = Promise.all(collateralsPromises)

      const [gas, collaterals] = await Promise.all([
        gasPromise,
        collateralsPromise,
      ])

      return collaterals.reduce((acc, { marketId, collateral }) => {
        acc.set(marketId, { gas, collateral })
        return acc
      }, new Map())
    },
  })
}

export const useWalletMarketBalancesQuery = (
  props: ContextStoreProp<"standard">,
  options?: Pick<QueryObserverOptions, "enabled">,
) => {
  const client = useStore(props.contextStore, (state) => state.chain.client)
  const chainDenom = useStore(
    props.contextStore,
    (state) => state.chain.config.asset.base,
  )
  const markets = useStore(props.contextStore, (state) => state.markets)
  const walletAddress = useChainStore(
    (state) => state.connectedWalletSession()?.walletAddress,
  )

  return useQuery({
    ...walletMarketBalancesQueryOptions(
      client,
      chainDenom,
      markets,
      walletAddress,
    ),
    ...options,
  })
}
