import type { OfflineSigner } from "@cosmjs/proto-signing"

import type { CreateAppErrorKeyOptions } from "@future/libs/error/utils"

import type { WalletRegistry } from "../registry"
import icon from "./icon.png"

declare global {
  interface Window {
    keplr?: KeplrProvider
  }
}

export interface KeplrProvider {
  defaultOptions?: {
    sign?: { preferNoSetMemo?: boolean }
  }
  experimentalSuggestChain: (chainInfo: unknown) => Promise<void>
  getOfflineSigner: (chainId: string) => OfflineSigner | undefined
}

export type Brand = "keplr"

export const rejectMessage = "Request rejected"

const errorOptions: CreateAppErrorKeyOptions = (message) => {
  switch (message) {
    case "key doesn't exist":
      return {
        key: "wallet.noAccount",
        presentable: true,
        level: "suppress",
      }
    case rejectMessage:
      return {
        key: "wallet.rejected",
        disablePresentation: true,
        level: "suppress",
      }
    case "Proposal expired": // QR Code expired
      return {
        key: "wallet.rejected",
        disablePresentation: true,
        level: "suppress",
      }
    case "You do not have enough funds.": // Only on Injective
      return {
        key: "wallet.insufficientFunds",
        presentable: true,
        level: "suppress",
      }
    default:
      return undefined
  }
}

export const registry: WalletRegistry<KeplrProvider> = {
  name: "Keplr",
  icon,
  provider: () => window.keplr,
  offlineSigner: (chainId) => {
    return registry.provider()?.getOfflineSigner(chainId)
  },
  install: {
    chrome:
      "https://chrome.google.com/webstore/detail/keplr/dmkamcknogkgcdfhhbddcghachkejeap",
    firefox: "https://addons.mozilla.org/en-US/firefox/addon/keplr",
    edge: "https://microsoftedge.microsoft.com/addons/detail/keplr/ocodgmmffbkkeecmadcijjhkmeohinei",
    ios: "https://apps.apple.com/us/app/keplr-wallet/id1567851089",
    android:
      "https://play.google.com/store/apps/details?id=com.chainapsis.keplr",
  },
  errorOptions,
  accountChangeKey: "keplr_keystorechange",
}
