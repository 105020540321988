import type { CreateAppErrorKeyOptions } from "@future/libs/error/utils"

import type { WalletRegistry } from "../registry"
import icon from "./icon.png"

declare global {
  interface Window {
    fin?: unknown
  }
}

export type Brand = "fin"

export const rejectMessage = "User rejected the request."

const errorOptions: CreateAppErrorKeyOptions = (message) => {
  switch (message) {
    case rejectMessage:
      return {
        key: "wallet.rejected",
        disablePresentation: true,
        level: "suppress",
      }
    // case "View-only wallet cannot be used for transactions or interactions in the Dapp.":
    //   return
    default:
      return undefined
  }
}

export const registry: WalletRegistry = {
  name: "Fin",
  icon,
  provider: () => window.fin,
  offlineSigner: () => undefined,
  install: {
    chrome:
      "https://chrome.google.com/webstore/detail/fin-wallet-for-sei/dbgnhckhnppddckangcjbkjnlddbjkna",
    // firefox: "https://addons.mozilla.org/en-US/firefox/addon/fin-wallet/", // Broken link
    edge: "https://microsoftedge.microsoft.com/addons/detail/fin-wallet-for-sei/dicbjakpncejbglbiapjhgdfhoomeomh",
    android:
      "https://play.google.com/store/apps/details?id=crypto.finwhale.wallet.sei",
  },
  errorOptions,
  accountChangeKey: "accountsChanged",
}
