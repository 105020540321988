import type { CreateAppErrorKeyOptions } from "@future/libs/error/utils"

import type { WalletRegistry } from "../registry"
import icon from "./icon.png"
import { type KeplrProvider, registry as keplrRegistry } from "../keplr"

declare global {
  interface Window {
    xfi?: XdefiProvider
  }
}

export interface XdefiProvider {
  keplr?: KeplrProvider
}

export type Brand = "xdefi"

const errorOptions: CreateAppErrorKeyOptions = (message) => {
  switch (message) {
    case "XDEFI: user rejected the message signing":
      return {
        key: "wallet.rejected",
        disablePresentation: true,
        level: "suppress",
      }
    default:
      return undefined
  }
}

export const registry: WalletRegistry<XdefiProvider> = {
  name: "XDEFI",
  icon,
  provider: () => window.xfi,
  offlineSigner: (chainId) => {
    return registry.provider()?.keplr?.getOfflineSigner(chainId)
  },
  install: {
    chrome:
      "https://chrome.google.com/webstore/detail/xdefi-wallet/hmeobnfnfcmdkdcmlblgagmfpfboieaf",
  },
  errorOptions,
  accountChangeKey: keplrRegistry.accountChangeKey,
}
