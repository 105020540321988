import Button from "@mui/joy/Button"
import Typography from "@mui/joy/Typography"
import { useTranslation } from "react-i18next"
import { useCallback, useState } from "react"

import type { MarketId } from "@perps/sdk/types"
import NavigationModal, {
  NavigationModalDynamicItem,
} from "@future/libs/theme/NavigationModal"
import type { ContextStoreProp } from "@future/context/store"

import HeaderFaucetValidate from "../HeaderFaucetValidate"
import { HeaderFaucetNavigation } from "./types"

export interface HeaderFaucetModalProps extends ContextStoreProp<"standard"> {
  errorMessage?: string
  marketId: MarketId | undefined
}

const HeaderFaucetModal = (props: HeaderFaucetModalProps) => {
  const { errorMessage } = props
  const { t } = useTranslation("faucet")

  const [isNavigationDisabled, setIsNavigationDisabled] = useState(false)
  const disableNavigation = useCallback(() => setIsNavigationDisabled(true), [])
  const enableNavigation = useCallback(() => setIsNavigationDisabled(false), [])

  return (
    <NavigationModal rootId={HeaderFaucetNavigation.validate}>
      {(modal) => (
        <>
          <NavigationModalDynamicItem
            id={HeaderFaucetNavigation.validate}
            title={
              errorMessage
                ? t("modal.faucetLimit.title")
                : t("modal.validate.title")
            }
            canClose={!isNavigationDisabled}
          >
            {errorMessage === undefined ? (
              <HeaderFaucetValidate
                {...modal}
                onSubmit={disableNavigation}
                onFailure={enableNavigation}
                contextStore={props.contextStore}
                marketId={props.marketId}
              />
            ) : (
              <>
                <Typography level="body2" sx={{ textAlign: "center", mb: 4 }}>
                  {errorMessage}
                </Typography>
                <Button
                  fullWidth
                  onClick={modal.close}
                  aria-label="faucet limit"
                >
                  {t("modal.faucetLimit.continue")}
                </Button>
              </>
            )}
          </NavigationModalDynamicItem>

          <NavigationModalDynamicItem
            id={HeaderFaucetNavigation.funded}
            title={t("modal.funded.title")}
            canClose
          >
            <Typography level="body2" sx={{ textAlign: "center", mb: 4 }}>
              {t("modal.funded.description")}
            </Typography>
            <Button fullWidth onClick={modal.close} aria-label="faucet funded">
              {t("modal.funded.continue")}
            </Button>
          </NavigationModalDynamicItem>
        </>
      )}
    </NavigationModal>
  )
}

export default HeaderFaucetModal
