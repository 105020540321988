import type { NavigationModalControlProps } from "@future/libs/theme/NavigationModalDialog"
import type { ContextStoreProp } from "@future/context/store"

import { useController } from "./controller"
import WalletConnectList from "../WalletConnectList"

export type WalletConnectRootProps = NavigationModalControlProps &
  ContextStoreProp<"standard">

const WalletConnectRoot = (props: WalletConnectRootProps) => {
  const controller = useController(props)

  return (
    <WalletConnectList
      connectors={controller.connectors}
      onConnectorClick={controller.handleClick}
      device={controller.device}
      onDeviceChange={controller.handleDeviceChange}
      retryConnectorId={controller.retryConnectorId}
      waitingMobileConnectorId={controller.waitingMobileConnectorId}
    />
  )
}

export default WalletConnectRoot
