import Container, { type ContainerProps } from "@mui/joy/Container"
import { type PropsWithChildren, useEffect } from "react"
import { useStore } from "zustand"

import { Event } from "@perps/analytics/events"
import { EventKey } from "@perps/analytics/constants"
import { track } from "@perps/analytics/track"
import FooterLayout from "@perps/footer/FooterLayout"
import HeaderLayout from "@perps/header/HeaderLayout"
import BannerProvider from "@perps/banner/BannerContext"
import type { PerpsRoute } from "@future/router/Router"
import { useSecondaryNavList } from "@future/header/navList"
import SystemHealth from "@future/systemHealth"
import AnnouncementsBanner from "@future/banner/AnnouncementsBanner"
import { updateTargetDataInReports } from "@future/target/state"
import UpdatesBanner from "@future/banner/UpdatesBanner"
import ReportEnabledDecider from "@future/libs/error/ReportEnabledDecider"
import { ModalPresenter } from "@future/libs/modal"
import WalletConnectModal from "@common/wallet/WalletConnectModal"
import LvnBanner from "@future/banner/LvnBanner"
import SeiSuspendBanner from "@future/banner/SeiSuspendBanner"
import { useChainStore } from "@future/libs/chain/store"
import type { ContextStoreProp } from "@future/context/store"
import { useClosedPositionsStore } from "@future/market/positions/closed/store"
import ReferralModalPresenter from "@future/referral/ReferralModalPresenter"

import OnlineWatcher from "../OnlineWatcher"

const RootLayout = (props: PropsWithChildren<ContextStoreProp<"standard">>) => {
  const routeRootId = useStore(
    props.contextStore,
    (state) => state.route.rootId,
  )

  const withConnectButton = routeRootId !== "stats"

  const secondaryNavList = useSecondaryNavList({
    withConnectButton,
    contextStore: props.contextStore,
  })

  usePageViewedAnalytics(props)
  useClearClosedPositions(props)
  useSyncReportMetadata(props)

  return (
    <BannerProvider>
      <ReportEnabledDecider />
      <SystemHealth contextStore={props.contextStore} />
      <OnlineWatcher />
      <AnnouncementsBanner />
      <UpdatesBanner />
      <SeiSuspendBanner contextStore={props.contextStore} />
      <LvnBanner contextStore={props.contextStore} />

      <ModalPresenter queueKey={WalletConnectModal.name}>
        <WalletConnectModal contextStore={props.contextStore} />
      </ModalPresenter>

      <ReferralModalPresenter contextStore={props.contextStore} />

      <HeaderLayout
        contextStore={props.contextStore}
        generalList={secondaryNavList.items}
        withConnectButton={withConnectButton}
      />

      <Container
        // disableGutters // ???: should this be done for mobile
        maxWidth={getMaxBreakpoint(routeRootId)}
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          flexWrap: "nowrap",
          justifyContent: "space-between",
        }}
      >
        {props.children}
        <FooterLayout />
      </Container>
    </BannerProvider>
  )
}

const getMaxBreakpoint = (
  rootId: PerpsRoute["route"] | undefined,
): ContainerProps["maxWidth"] => {
  switch (rootId) {
    case "markets":
      return "xl"
    case "earnMarket":
      return "xl"
    case "earnList":
      return "xl"
    case "history":
      return "lg"
    case "leaderboard":
      return "lg"
    case "stats":
      return "xl"
    case "trade":
      return false
    case undefined:
      return "lg"
  }
}

const usePageViewedAnalytics = (props: ContextStoreProp<"standard">) => {
  const walletAddress = useChainStore(
    (state) => state.connectedWalletSession()?.walletAddress,
  )
  const targetId = useStore(props.contextStore, (state) => state.targetInfo.id)

  // biome-ignore lint/correctness/useExhaustiveDependencies: rerender for `targetId` and `address`
  useEffect(() => {
    track(
      Event.pageViewed({
        [EventKey.URL_PATH]: `${location.pathname}${location.search}`,
      }),
    )
  }, [targetId, walletAddress])
}

const useClearClosedPositions = (props: ContextStoreProp<"standard">) => {
  const targetId = useStore(props.contextStore, (state) => state.targetInfo.id)
  const walletAddress = useChainStore(
    (state) => state.connectedWalletSession()?.walletAddress,
  )

  // biome-ignore lint/correctness/useExhaustiveDependencies: clear for targetId
  useEffect(() => {
    if (!walletAddress) {
      useClosedPositionsStore.getState().clear()
    }
  }, [targetId, walletAddress])
}

const useSyncReportMetadata = (props: ContextStoreProp<"standard">) => {
  const targetId = useStore(props.contextStore, (state) => state.targetInfo.id)
  const factoryAddress = useStore(
    props.contextStore,
    (state) => state.chain.config.factoryAddress,
  )

  useEffect(() => {
    updateTargetDataInReports(targetId, factoryAddress)
  }, [targetId, factoryAddress])
}

export default RootLayout
