import type { SvgIconProps } from "@mui/joy/SvgIcon"
import type { OfflineSigner } from "@cosmjs/proto-signing"

import type { CreateAppErrorKeyOptions } from "@future/libs/error/utils"

import * as Compass from "./compass"
import * as Cosmostation from "./cosmostation"
import * as Fin from "./fin"
import * as Keplr from "./keplr"
import * as Leap from "./leap"
import * as LeapMetaMask from "./leapMetaMask"
import * as MetaMask from "./metaMask"
import * as OKX from "./okx"
import * as Viewing from "./viewing"
import * as Xdefi from "./xdefi"

export type WalletBrand =
  | Compass.Brand
  | Cosmostation.Brand
  | Fin.Brand
  | Keplr.Brand
  | Leap.Brand
  | LeapMetaMask.Brand
  | MetaMask.Brand
  | OKX.Brand
  | Viewing.Brand
  | Xdefi.Brand

export interface WalletRegistry<Provider = unknown> {
  name: string
  icon: string | React.JSXElementConstructor<SvgIconProps>
  /**
   * The extension object on the `window`
   */
  provider: () => Provider | undefined
  offlineSigner: (chainId: string) => OfflineSigner | undefined
  install: {
    chrome: string
    firefox?: string
    edge?: string
    ios?: string
    android?: string
  }
  errorOptions: CreateAppErrorKeyOptions
  /**
   * Event listener keys for when a wallet extension changes accounts
   */
  accountChangeKey: string | undefined
}

export const walletRegistryMap = {
  compass: Compass.registry,
  cosmostation: Cosmostation.registry,
  fin: Fin.registry,
  keplr: Keplr.registry,
  leap: Leap.registry,
  leapMetaMask: LeapMetaMask.registry,
  metaMask: MetaMask.registry,
  okx: OKX.registry,
  viewing: Viewing.registry,
  xdefi: Xdefi.registry,
} satisfies Record<WalletBrand, WalletRegistry>
