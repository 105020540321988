import Box from "@mui/joy/Box"
import CircularProgress from "@mui/joy/CircularProgress"
import Typography from "@mui/joy/Typography"
import { useTranslation } from "react-i18next"

import FaucetForm from "@perps/faucet/FaucetForm"
import type { MarketId } from "@perps/sdk/types"
import type { NavigationModalControlProps } from "@future/libs/theme/NavigationModalDialog"
import type { ContextStoreProp } from "@future/context/store"

import { useController } from "./controller"

export interface HeaderFaucetValidateProps
  extends ContextStoreProp<"standard">,
    NavigationModalControlProps {
  onSubmit: () => void
  onFailure: () => void
  marketId: MarketId | undefined
}

const HeaderFaucetValidate = (props: HeaderFaucetValidateProps) => {
  const controller = useController(props)
  const { t } = useTranslation("faucet")

  return (
    <>
      <Typography level="body2" sx={{ textAlign: "center", mb: 4 }}>
        {t("modal.validate.description")}
      </Typography>

      {controller.isLoading ? (
        <Box sx={{ lineHeight: 0, textAlign: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <FaucetForm
          submitFromCaptcha
          onSubmit={controller.handleSubmit}
          onSuccess={controller.handleSuccess}
          onFailure={controller.handleFailure}
          getCw20s={controller.getCw20s}
          contextStore={props.contextStore}
          marketId={props.marketId}
        />
      )}
    </>
  )
}

export default HeaderFaucetValidate
