import {
  type QueryObserverOptions,
  useQuery,
  queryOptions,
  useQueries,
} from "@tanstack/react-query"
import type { Asset } from "@skip-go/client"

import { useSkip } from "@future/swap/skip"
import { chainConfigMap } from "@common/target/constants"

export const skipAssetsQueryOptions = (chainId: string) => {
  return queryOptions({
    queryKey: ["skipAssets", chainId],
    staleTime: Number.POSITIVE_INFINITY,
    queryFn: async () => {
      const chainNetworkConfigs = Object.values(chainConfigMap)
      const chainNetworkConfig = chainNetworkConfigs.find(
        (chainNetworkConfig) => chainNetworkConfig.chainId === chainId,
      )

      const skip = await useSkip.getState()
      const assets = await skip.client.assets({
        chainIDs: [chainId],
        onlyTestnets: chainNetworkConfig?.network === "testnet",
      })

      return assets
    },
    select: (data) => {
      return data[chainId]
    },
  })
}

export const useSkipAssetsQuery = (
  chainId: string,
  options?: Pick<QueryObserverOptions, "enabled">,
) => {
  return useQuery({
    ...skipAssetsQueryOptions(chainId),
    ...options,
  })
}

export const useSkipAssetsQueries = (
  chainIds: string[],
  options?: Pick<QueryObserverOptions, "enabled">,
) => {
  return useQueries({
    queries: chainIds.map((chainId) => ({
      ...skipAssetsQueryOptions(chainId),
      ...options,
    })),
    combine: (results) => {
      const data = results.reduce(
        (accumulator, result, index) => {
          const chainId = chainIds[index]

          if (result.data) {
            accumulator[chainId] = result.data ?? []
          }

          return accumulator
        },
        {} as Record<string, Asset[]>,
      )

      return {
        data: Object.keys(data).length === chainIds.length ? data : undefined,
      }
    },
  })
}
